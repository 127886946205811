<template>
  <div class="forget-password">
    <div class="box">
      <div class="box-input">
        <input v-model.trim="realName" type="text" placeholder="请输入真实姓名"/>
      </div>
    </div>
    <p :class="['next','next-sure']" @click="nextFun">确定</p>
    <toast-box v-if="showToast" :show-toast="showToast" :msg="msg" @sure="handleSure"></toast-box>
  </div>
</template>
<script>
import ToastBox from "../../components/toast.vue";
import {
  updateMemberName
} from '@/utils/loginRegister.js';
export default {
  name: "setRealName",
  components: { 
    ToastBox
  },
  data() {
    return {
      msg: "",
      showToast: false,
      realName: "",
    };
  },
  methods: {
    nextFun() {
      if (!this.realName && this.realName !== 0) {
        this.showToast = true;
        this.msg = "真实姓名不能为空";
        return
      }

      let data1 = {
        memberId: this.$cookies.get('memberId'),
        realName: this.realName
      };
      updateMemberName(data1).then(res => {
        if (res && res.code == 0) {
          this.msg = "真实姓名修改成功!";
          this.showToast = true;
        }
      })
    },
    handleSure() {
      this.$router.push("/user")
    },
  }
};
</script>
<style lang="scss" scoped>
.forget-password {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #ffffff;
  overflow-x: hidden;
  overflow-y: scroll;
  .title {
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 33px;
    margin: 30px 0 35px 30px;
    text-align: left;
  }
  .box {
    width: 315px;
    margin: 0 auto;
    border-bottom: solid 1px #f5f5f5;
    text-align: left;
    position: relative;
    .code {
      position: absolute;
      bottom: 14px;
      right: 0;
      width: 84px;
      height: 28px;
      -webkit-border-radius: 14px;
      -moz-border-radius: 14px;
      -ms-border-radius: 14px;
      -o-border-radius: 14px;
      border-radius: 14px;
      border: 1px solid #e62129;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #e62129;
      line-height: 28px;
      text-align: center;
    }
    .count {
      position: absolute;
      bottom: 14px;
      right: 0;
      width: 84px;
      height: 28px;
      -webkit-border-radius: 14px;
      -moz-border-radius: 14px;
      -ms-border-radius: 14px;
      -o-border-radius: 14px;
      border-radius: 14px;
      border: 1px solid #cccccc;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      text-align: center;
      line-height: 28px;
    }
    .box-input {
      height: 22px;
      padding: 25px 0 15px 0;
      input {
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
      }
      input::-webkit-input-placeholder {
        color: #cccccc;
      }
    }
  }
  .next {
    width: 315px;
    height: 44px;
    background: #e62129;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    -ms-border-radius: 22px;
    -o-border-radius: 22px;
    border-radius: 22px;
    opacity: 0.5;
    margin: 0 auto;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 44px;
    text-align: center;
    margin-top: 80px;
  }
  .next-sure {
    opacity: 1;
  }
}
</style>