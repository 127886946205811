<template>
  <div class="forget-password">
    <p class="title">忘记密码</p>
    <div class="box">
      <div class="box-input">
        <input v-model.trim="phone" type="tel" placeholder="请输入手机号" maxlength="11"/>
      </div>
    </div>
    <div class="box">
      <div class="box-input">
        <input v-model.trim="smsCode" type="tel" placeholder="请输入验证码" maxlength="6"/>
      </div>
      <p v-show="timeShow" class="code" @click="getCode">获取验证码</p>
      <p v-show="!timeShow" class="count">{{ count }}s</p>
    </div>
    <p :class="['next', { 'next-sure': (this.phone || this.phone === 0) && (this.smsCode || this.smsCode === 0) }]" @click="nextFun">下一步 设置密码</p>
    <toast-box v-if="showToast" :show-toast="showToast" :msg="msg" @sure="handleSure"></toast-box>
  </div>
</template>
<script>
import { getverifycode, getmemberinfo } from "@/utils/loginRegister.js";
import ToastBox from "../../components/toast.vue";
export default {
  name: "ForgetPassword",
  components: { 
    ToastBox
  },
  data() {
    return {
      msg: "",
      showToast: false,
      phone: "",
      smsCode: "",
      count: "",
      timer: null,
      timeShow: true,
      oldPhone: ""
    };
  },
  created() {
     this.queryOldPhone()
  },
  methods: {
    queryOldPhone() {
      getmemberinfo({
        memberId: this.$cookies.get('memberId'),
      }).then((res) => {
        if (res.code == "0") {
          this.oldPhone = res.data.user_tele || ""
          this.phone = res.data.user_tele || ""
        } else {
          this.$toast.fail(res.message);
        }
      });
    },
    nextFun() {
      if (!this.phone && this.phone !== 0) {
        return
      } else if (!/^1\d{10}$/.test(this.phone)) {
        this.showToast = true;
        this.msg = "手机号填写错误<br>请填写11位正确的手机号";
        return
      } else if (!this.smsCode && this.smsCode !== 0) {
        return
      } else if (!/^\d{6}$/.test(this.smsCode)) {
        this.showToast = true;
        this.msg = "验证码填写错误<br>请重新填写正确的验证码";
        return
      }
      // if (this.oldPhone && String(this.phone) !== String(this.oldPhone)) {
      //   this.showToast = true;
      //   this.msg = "手机号与平台之前绑定的手机号不一致";
      //   return
      // } else {
      sessionStorage.setItem("forgetInfo", JSON.stringify({
        phone: this.phone,
        smsCode: this.smsCode
      }))
      this.$router.push("/resetPassword")
      // }
    },
    handleSure(val) {
      this.showToast = val;
    },
    getCode() {
      if (!this.phone && this.phone !== 0) {
        return this.$toast("请输入手机号")
      } else if (!/^1\d{10}$/.test(this.phone)) {
        this.showToast = true;
        this.msg = "手机号填写错误<br>请填写11位正确的手机号";
      } else {
        getverifycode({
          phone: this.phone,
        }).then((res) => {
          if (res.code == "0") {
            this.$toast.success("验证码已发送");
            this.code();
          } else {
            this.$toast.fail(res.message);
          }
        });
      }
    },
    //获取验证码倒计时
    code() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.timeShow = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.timeShow = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.forget-password {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #ffffff;
  overflow-x: hidden;
  overflow-y: scroll;
  .title {
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 33px;
    margin: 30px 0 35px 30px;
    text-align: left;
  }
  .box {
    width: 315px;
    margin: 0 auto;
    border-bottom: solid 1px #f5f5f5;
    text-align: left;
    position: relative;
    .code {
      position: absolute;
      bottom: 14px;
      right: 0;
      width: 84px;
      height: 28px;
      -webkit-border-radius: 14px;
      -moz-border-radius: 14px;
      -ms-border-radius: 14px;
      -o-border-radius: 14px;
      border-radius: 14px;
      border: 1px solid #e62129;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #e62129;
      line-height: 28px;
      text-align: center;
    }
    .count {
      position: absolute;
      bottom: 14px;
      right: 0;
      width: 84px;
      height: 28px;
      -webkit-border-radius: 14px;
      -moz-border-radius: 14px;
      -ms-border-radius: 14px;
      -o-border-radius: 14px;
      border-radius: 14px;
      border: 1px solid #cccccc;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      text-align: center;
      line-height: 28px;
    }
    .box-input {
      height: 22px;
      padding: 25px 0 15px 0;
      input {
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
      }
      input::-webkit-input-placeholder {
        color: #cccccc;
      }
    }
  }
  .next {
    width: 315px;
    height: 44px;
    background: #e62129;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    -ms-border-radius: 22px;
    -o-border-radius: 22px;
    border-radius: 22px;
    opacity: 0.5;
    margin: 0 auto;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 44px;
    text-align: center;
    margin-top: 80px;
  }
  .next-sure {
    opacity: 1;
  }
}
</style>